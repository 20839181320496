globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"64e7130ef488917df2635826edfb461aecd387d5"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import posthog from 'posthog-js';
import sentryConfig from 'sentry.config';

Sentry.init({
  ...sentryConfig,
  // Replay is only valid on the client.
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: true,
      blockAllMedia: false,
    }),
    posthog.sentryIntegration({
      prefix: 'https://carbonfact-eu.sentry.io/',
      organization: 'carbonfact-eu',
      projectId: 4507572532543568, // 'platform' project
    }),
  ],
});
